<template>
  <CRow v-if="$store.state.providers !== null">
    <CCol class="w-100 justify-content-center">
      <CCard>
        <modal-refuse-verification
            :provider_id="provider_id"
            :showRefuseVerificationModal="modalRefuseVerifycation"
            @closed="refuseVerification()"
        />
        <modal-any-information :show-any-information-modal="alertModalShow" @close="alertModal()">
          <template v-slot:body>
            <h4>Провайдер, ще не підтвердив емейл</h4>
          </template>
        </modal-any-information>
        <CCard>
          <CCardHeader>

            <CRow class="align-items-center">
              <CCol col="12" sm="7" md="8" xl class="mb-3 mb-xl-0 text-left">
                <h2>Користувачі (Провайдери)</h2>
              </CCol>
              <CCol col="12" sm="5" md="4" class="mb-3 mb-xl-0 text-right">
                <BtnCSV v-if="$store.state.providers !== null"
                        class="ml-2 mt-1 p-1 px-2"
                        value="Вигрузити провайдерів в CSV"
                        url="providers"
                />
                <!--                <CButton v-if="$store.state.providers !== null" class="ml-2 mt-1 p-1 px-2" @click="getCSV"-->
                <!--                         color="success" variant="outline">Вигрузити провайдерів в CSV-->
                <!--                </CButton>-->
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

            <all-providers-grid
                @refuseVerification="(provider_id) => refuseVerification(provider_id,true)"
                @warningVerification="alertModal(true)"
            />
          </CCardBody>
        </CCard>
      </CCard>
    </CCol>
  </CRow>

</template>

<script>
import ModalRefuseVerification from "../Components/Modal/ModalRefuseVerification";
import AllProvidersGrid from "../Components/Grid/AllProvidersGrid";
import ModalAnyInformation from "../Components/Modal/ModalAnyInformation";
import BtnCSV from "../Components/Button/BtnCSV";

export default {
  name: "Admins",
  components: {ModalRefuseVerification, AllProvidersGrid, ModalAnyInformation, BtnCSV},
  data: () => {
    return {
      warningVerification: false,
      provider_id: '',
      modalRefuseVerifycation: false,
      alertModalShow: false
    }
  },
  methods: {
    refuseVerification(provider_id = 0, show = false) {
      this.provider_id = provider_id
      this.modalRefuseVerifycation = show;
      if (!show) {
        this.$store.dispatch('GET_PROVIDER');
      }
    },
    alertModal(show = false) {
      this.alertModalShow = show;
    },
  },
  created() {
    this.$store.dispatch('GET_PROVIDER');
  }
}
</script>
