<template>
  <!--  Admin Portal-->
  <CDataTable
      hover
      striped
      :items="$store.state.providers"
      :fields="fieldsProviders"
      :items-per-page="perPage"
      :items-per-page-select="{label:'Кількість на сторінці: ', values:[5,10,20,30,50]}"
      index-column
      :sorter-value="{column:'is_verify', asc:true}"
      :table-filter="{lazy: false, placeholder: 'Знайти...', label: ' '}"
      :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available' }"
      sorter
      :pagination="$options.paginationProps"
  >
    <template #brand="data">
      <td>
        {{ data.item.brand }}
      </td>
    </template>
    <template #name="data">
      <td>
        {{ data.item.name }}
      </td>
    </template>
    <template #is_pipeline="data">
      <td>
        {{ data.item.is_pipeline ? "Так" : "Ні" }}
      </td>
    </template>
    <template #is_paper_less="data">
      <td>
        {{ data.item.is_paper_less ? "Так" : "Ні" }}
      </td>
    </template>
    <template #edr="data">
      <td v-if="!data.item.edr" title="ІПН">
        {{ data.item.ipn }}
      </td>
      <td v-else title="ЄДРПОУ">
        {{ data.item.edr }}
      </td>
    </template>
    <template #ipn>
      <td></td>
    </template>
    <template #rating="data">
      <td>
        <div class='ctrl'>
          <div
              @click="changeRating(data.item.rating -1 ,data.item.id)"
              class='ctrl__button ctrl__button--decrement'
          >&ndash;
          </div>
          <div class='ctrl__counter'>
            <input class='ctrl__counter-input' maxlength='10' type='text' value='0'>
            <div class='ctrl__counter-num'>{{ data.item.rating }}</div>
          </div>
          <div
              @click="changeRating(data.item.rating +1,data.item.id)"
              class='ctrl__button ctrl__button--increment'
          >+
          </div>
        </div>
      </td>
    </template>
    <template #show_details="data">
      <td>
        <CButton
            color="primary"
            variant="outline"
            size="sm"
            @click="showProviderDetails(data.item.id)"
        >
          Показати
        </CButton>
      </td>
    </template>
    <template #is_verify="data">
      <td class="text-center">
        <CButton
            class="p-1 text-center"
            :color="getChangeColor(data.item.is_verify)"
            @click="approveVerification(data.item.id)"
            :disabled="data.item.is_verify"
        >
          {{ data.item.is_verify ? "Верифікований" : "Підтвердити" }}
        </CButton>
        <CButton
            v-if="!data.item.is_verify"
            class="p-1 mt-1 text-center"
            color="danger"
            @click="refuseVerification(data.item.id, true)"
        >
          Відмінити
        </CButton>
      </td>
    </template>
    <template #is_blocked="data">
      <td>
        <un-block-provider :provider="data.item"/>
      </td>
    </template>
  </CDataTable>

</template>

<script>
import UnBlockProvider from "../../Components/unBlockProvider";
import {fieldsProviders} from "./Fields";
import axios from "axios";

export default {
  name: "AllProvidersGrid",
  components: {UnBlockProvider},
  data() {
    return {
      perPage: 20,
      activePage: 1,
      fieldsProviders
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: true,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next',
  },
  methods: {
    getChangeColor(is_verify) {
      return is_verify ? 'secondary'
          : !is_verify ? 'success'
              : 'primary'
    },
    approveVerification(id) {
      let self = this;
      axios.post(`api/providers/${id}/verify`)
          .then(function () {
            self.$store.dispatch('GET_PROVIDER');
          })
          .catch(function () {
            self.$emit('warningVerification');
          })
    },
    showProviderDetails(id) {
      // `/admin/providers/${id.toString()}`
      let providersLink = {name: 'BankDetailsProviders', params: {id: id.toString()}};
      let link = this.$router.resolve(providersLink)
      window.open(link.href, '_blank');
    },
    refuseVerification(provider_id) {
      this.$emit('refuseVerification', provider_id);
      // this.provider_id = provider_id
      // this.Verify = !this.Verify;
    },
    changeRating(rating, id) {
      let self = this
      if (rating >= 1 && rating <= 10) {
        axios.post('/api/providers/' + id + '/rating/' + rating)
            .then(function () {
              self.$store.dispatch('GET_PROVIDER');
            })
            .catch(function () {
            })
      }
    },
  }

}
</script>
