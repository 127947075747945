<template>
  <CButton
    class="p-1 px-2"
    @click="download_csv"
    :color="color"
    variant="outline"
    v-bind="$attrs"
  >
    {{ value }}
  </CButton>
</template>

<script>
import axios from "axios";

export default {
  name: "BtnCSV",
  props: {
    value: {type: String, required: true, default: 'Вигрузка CSV'},
    color: {type: String, required: false, default: 'success'},
    url: {type: String, required: true, default: ''}
  },
  urlCSV: {
    providers: '/api/users/csv',
    tenders: '/api/orders/csv',
    statistics_status_tenders: '/api/statistics/orders/csv',
    statistics_number_of_corrected_tenders: '/api/statistics/quality/csv',
    statistics_number_providers: '/api/statistics/providers/csv',
    statistics_providers_activity: '/api/statistics/offers/csv',
    statistics_connection_cost: `/api/statistics/cost/csv`,
    statistics_subscription_cost: `/api/statistics/cost/csv`,
    statistics_subscription_cost_for_1_mbit: `/api/statistics/cost/csv`,
  },
  methods: {
    download_csv() {
      axios({
        url: this.$options.urlCSV[this.$props.url],
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.$props.url}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch(() => {
      });
    },
  }
}
</script>
